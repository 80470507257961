import { useState } from 'react';
import { signDataProcessingAgreementType } from 'services/api';

import Button from 'components/Button';
import Icon from 'components/Icon';

const DpaConfirmation = ({ site, confirm }: { site: Site; confirm: () => void }) => {
  const [isDpaConfirmed, setIsDpaConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const signDpa = () => {
    setIsConfirming(true);

    signDataProcessingAgreementType(site.id, 'reviews')
      .then(() => {
        setIsSigned(true);
        setTimeout(() => {
          confirm();
        }, 1000);
      })
      .catch(() => {
        alert('Could not sign data processing agreement. Please try again or contact support.');
        setIsConfirming(false);
        setIsSigned(false);
      });
  };

  return (
    <div className="space-y-10 text-center">
      <p>
        When collecting reviews from your customers, your form will typically include a name and email address; we’ll
        keep them stored for you. To do that, we will need you to accept our{' '}
        <a href="https://flockler.com/dpa" target="_blank" rel="noreferrer" className="underline">
          data processing agreement
        </a>
        .
      </p>

      <div className="animate-fade-in space-y-5">
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={isDpaConfirmed}
              disabled={isConfirming}
              onChange={() => setIsDpaConfirmed(!isDpaConfirmed)}
            />
            <span className="ml-2">I agree to the Data Processing Agreement</span>
          </label>
        </div>

        {isConfirming ? (
          <div className="inline-flex h-12 w-72 items-center justify-center space-x-2 rounded-lg border border-slate-200 text-sm font-semibold ">
            {isSigned ? (
              <>
                <Icon type="checkmark-circle" className="text-green-700" />
                <span className="text-green">Signed!</span>
              </>
            ) : (
              <>
                <Icon type="spinner" className="animate-spin text-slate-400" />
                <span className="text-slate-500">Signing…</span>
              </>
            )}
          </div>
        ) : (
          <Button variant="success" disabled={!isDpaConfirmed} className="h-12 w-72" action={signDpa}>
            Sign the Data Processing Agreement
          </Button>
        )}
      </div>
    </div>
  );
};

DpaConfirmation.displayName = 'DpaConfirmation';
export default DpaConfirmation;
