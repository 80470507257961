// # Site concept

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as api from 'services/api';
import { showError } from 'concepts/error';
import { fetchPlans } from 'concepts/plan';
import { fetchSubscription } from 'concepts/subscription';
import { fetchAddons, fetchAddonTypes } from 'concepts/addon';
import { createAppMatchSelector } from 'redux/router';

// # Action types
const FETCH_SITE = 'site/FETCH_SITE';
const FETCH_SITE_SUCCESS = 'site/FETCH_SITE_SUCCESS';
const FETCH_SITE_FAIL = 'site/FETCH_SITE_FAIL';

const FETCH_SITES = 'site/FETCH_SITES';
const FETCH_SITES_SUCCESS = 'site/FETCH_SITES_SUCCESS';
const FETCH_SITES_FAIL = 'site/FETCH_SITES_FAIL';

const FETCH_SITE_SETTINGS = 'site/FETCH_SITE_SETTINGS';
const FETCH_SITE_SETTINGS_SUCCESS = 'site/FETCH_SITE_SETTINGS_SUCCESS';
const FETCH_SITE_SETTINGS_FAIL = 'site/FETCH_SITE_SETTINGS_FAIL';

export const UPDATE_SITE = 'site/UPDATE_SITE';
export const UPDATE_SITE_SUCCESS = 'site/UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAIL = 'site/UPDATE_SITE_FAIL';

export const UPDATE_SITE_SETTINGS = 'site/UPDATE_SITE_SETTINGS';
export const UPDATE_SITE_SETTINGS_SUCCESS = 'site/UPDATE_SITE_SETTINGS_SUCCESS';
export const UPDATE_SITE_SETTINGS_FAIL = 'site/UPDATE_SITE_SETTINGS_FAIL';

// # Selectors
export const getSites = (state: RootState) => state.site.sites as Array<Site>;
export const getSiteSettings = (state: RootState) => state.site.settings as SiteSettings;
export const getCurrentSiteState = (state: RootState) => state.site.currentSite as Site;
export const getSiteRequestStatus = (state: RootState) => state.site.siteRequestStatus;
export const getSitesRequestStatus = (state: RootState) => state.site.sitesRequestStatus;
export const getSiteUpdateRequestStatus = (state: RootState) => state.site.siteUpdateRequestStatus;
export const getSettingsRequestStatus = (state: RootState) => state.site.settingsRequestStatus;
export const getSettingsUpdateRequestStatus = (state: RootState) => state.site.settingsUpdateRequestStatus;

export const getSitesLoadingStatus = createSelector(
  getSiteRequestStatus,
  getSitesRequestStatus,
  (siteReq, sitesReq) => siteReq === 'loading' || sitesReq === 'loading'
);

export const getSiteSettingsLoadingStatus = createSelector(
  getSettingsRequestStatus,
  (settingsReq) => settingsReq === 'loading'
);

export const getSiteUrl = (state: RootState) => {
  const matchSelector = createAppMatchSelector('/:siteUrl');
  const match = matchSelector(state);

  return match?.params.siteUrl as string;
};

export const getRouteSite = createSelector(getSites, getSiteUrl, (sites, siteUrl) =>
  sites.find((site: PlainSite) => site.site_url === siteUrl)
);

export const getCurrentSite = createSelector(
  getCurrentSiteState,
  getRouteSite,
  (fullSite, partialSite) => (fullSite as Site) || (partialSite as Partial<Site>)
);

export const getSiteId = createSelector(getCurrentSite, (site) => site?.id);
export const getSiteUuid = createSelector(getCurrentSite, (site) => site?.uuid);

export const getCurrentSiteId = createSelector(getCurrentSite, (site) => site?.id);
export const getSiteSections = createSelector(getCurrentSite, (site) => site?.sections);
export const getSiteLocale = createSelector(getCurrentSite, (site) => site?.locale);
export const getSiteCurrency = createSelector(getCurrentSite, (site) => site?.currency);

export const getSiteSetting = (name: string) =>
  createSelector(getSiteSettings, (settings: any) => (settings ? settings[name] : null));

export const getSections = createSelector(getCurrentSiteState, (site: Site) => {
  return site ? site.sections : undefined;
});

export const getHasCtaLinkEnabled = createSelector(getSiteSettings, (settings) => {
  return !!settings && (settings['newsroom_custom_components'] || []).includes('article-cta-link');
});

// # Actions
const fetchSites = () => (dispatch: any) => {
  dispatch({ type: FETCH_SITES });
  return api
    .fetchSites({ fields: 'id,site_url,name' })
    .then((response) => dispatch({ type: FETCH_SITES_SUCCESS, payload: response.data }))
    .catch((error) => dispatch({ type: FETCH_SITES_FAIL, payload: error?.response?.data }));
};

export const fetchSiteBySiteId = (siteId: SiteId) => (dispatch: any) => {
  dispatch({ type: FETCH_SITE });
  return api
    .fetchSite(siteId)
    .then((response) => dispatch({ type: FETCH_SITE_SUCCESS, payload: response.data }))
    .catch((error) => {
      dispatch(showError('Site not found'));
      return dispatch({ type: FETCH_SITE_FAIL, payload: error?.response?.data });
    });
};

export const fetchSiteSettings = (siteId: number) => (dispatch: any, getState: any) => {
  dispatch({ type: FETCH_SITE_SETTINGS });
  return api
    .fetchSiteSettings(siteId)
    .then((response) => dispatch({ type: FETCH_SITE_SETTINGS_SUCCESS, payload: response.data.settings }))
    .catch((error) => dispatch({ type: FETCH_SITE_SETTINGS_FAIL, payload: error }));
};

export const updateSite = (siteId: string | number, values: SiteUpdateParams) => (dispatch: any, getState: any) => {
  dispatch({ type: UPDATE_SITE });

  return api
    .updateSite(siteId, values)
    .then((response) => {
      const currentSite = getCurrentSiteState(getState());
      const updatedSite = response.data;

      if (currentSite?.site_url !== updatedSite.site_url) {
        window.location.pathname = window.location.pathname.replace(
          `/${currentSite?.site_url}/`,
          `/${updatedSite.site_url}/`
        );
      }

      return dispatch({ type: UPDATE_SITE_SUCCESS, payload: updatedSite });
    })
    .catch((error) => {
      dispatch({ type: UPDATE_SITE_FAIL, payload: error.response.data });
      return Promise.reject(error);
    });
};

export const updateSiteSettings =
  (siteId: string | number, values: SiteSettingsUpdateParams) => (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_SITE_SETTINGS });

    return api
      .updateSiteSettings(siteId, values)
      .then((response) => {
        return dispatch({ type: UPDATE_SITE_SETTINGS_SUCCESS, payload: response.data.settings });
      })
      .catch((error) => {
        dispatch({ type: UPDATE_SITE_SETTINGS_FAIL, payload: error.response.data });
        return Promise.reject(error);
      });
  };

export const initializeCurrentSite = (): any => (dispatch: any, getState: any) => {
  const siteUrl = getSiteUrl(getState());

  dispatch({ type: FETCH_SITE });

  return api
    .fetchSiteBySiteUrl(siteUrl)
    .then((response) => dispatch({ type: FETCH_SITE_SUCCESS, payload: response.data }))
    .then(() => {
      const currentSite = getCurrentSite(getState());

      if (siteUrl !== currentSite.site_url) {
        window.location.pathname = `/${currentSite.site_url}`;
        return;
      }

      const siteId = currentSite.id;

      return Promise.all([
        dispatch(fetchSiteSettings(siteId)),
        dispatch(fetchSubscription(siteId)),
        dispatch(fetchPlans(siteId)),
        dispatch(fetchAddons(siteId)),
        dispatch(fetchAddonTypes()),
      ]);
    })
    .catch((error) => {
      // Fetch site by site ID or UUID
      if (siteUrl.match(/^\d+$/) || siteUrl.match(/^[0-9a-z]{32}$/)) {
        return dispatch(fetchSiteBySiteId(siteUrl)).then(() => {
          const currentSite = getCurrentSite(getState());
          window.location.pathname = `/${currentSite.site_url}`;
        });
      } else {
        dispatch(showError('Site not found'));
        return dispatch({ type: FETCH_SITE_FAIL, payload: error?.response?.data });
      }
    });
};

export const initializeSites = (): any => (dispatch: any) => {
  return Promise.all([dispatch(initializeCurrentSite()), dispatch(fetchSites())]);
};

// # Reducer

export const initialState: SiteState = {
  currentSite: undefined,
  sites: [],
  settings: undefined,
  sitesRequestStatus: 'initial',
  siteRequestStatus: 'initial',
  siteUpdateRequestStatus: 'initial',
  settingsRequestStatus: 'initial',
  settingsUpdateRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: SiteActions): SiteState {
  switch (action.type) {
    case FETCH_SITE: {
      return {
        ...state,
        siteRequestStatus: 'loading',
      } as SiteState;
    }

    case FETCH_SITE_SUCCESS: {
      return {
        ...state,
        currentSite: (action as FetchSiteSuccessAction).payload,
        siteRequestStatus: 'success',
      };
    }

    case FETCH_SITE_FAIL: {
      return {
        ...state,
        siteRequestStatus: 'failure',
      };
    }

    case FETCH_SITE_SETTINGS: {
      return {
        ...state,
        settingsRequestStatus: 'loading',
      };
    }

    case FETCH_SITE_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: (action as FetchSiteSettingsSuccessAction).payload,
        settingsRequestStatus: 'success',
      };
    }

    case FETCH_SITE_SETTINGS_FAIL: {
      return {
        ...state,
        settingsRequestStatus: 'failure',
      };
    }

    case FETCH_SITES_SUCCESS: {
      return {
        ...state,
        sites: (action as FetchSitesSuccessAction).payload as PlainSite[],
        sitesRequestStatus: 'success',
      };
    }

    case UPDATE_SITE: {
      return {
        ...state,
        siteUpdateRequestStatus: 'loading',
      };
    }

    case UPDATE_SITE_SUCCESS: {
      return {
        ...state,
        currentSite: (action as UpdateSiteSuccessAction).payload,
        siteUpdateRequestStatus:
          state.currentSite?.site_url !== (action as UpdateSiteSuccessAction).payload.site_url ? 'loading' : 'success',
      };
    }

    case UPDATE_SITE_FAIL: {
      return {
        ...state,
        siteUpdateRequestStatus: 'failure',
      };
    }

    case UPDATE_SITE_SETTINGS: {
      return {
        ...state,
        settingsUpdateRequestStatus: 'loading',
      };
    }

    case UPDATE_SITE_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: (action as UpdateSiteSettingsSuccessAction).payload,
        settingsUpdateRequestStatus: 'success',
      };
    }

    case UPDATE_SITE_SETTINGS_FAIL: {
      return {
        ...state,
        settingsUpdateRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
